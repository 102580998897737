<template>
  <div class="top_list">
    <div class="top_header">
      <div class="back_button" @click="goBack">
        <!--        <span class="back">&#60;</span>-->
        <van-icon name="arrow-left" />
      </div>
      <div class="icon_img">
        <img src="/images/topRankList/logo.png" alt="img" />
      </div>
    </div>
    <div class="top_content">
      <div class="top_info"><span>显示近7天前50条热议帖子,每天更新一次</span></div>
      <div class="top_list_warp">
        <div class="top_item" v-for="(item, index) in data" :key="item.data" @click="jumpDetail(item.id)">
          <div class="item_left">
            <div class="item_index">
              <img v-if="index < 3" :src="`/images/topRankList/${index}.png`" alt="" />
              <span v-else>{{index+1}}</span>
            </div>
            <div class="item_title"><span>{{item.title || '无标题' }}</span></div>
          </div>
          <div class="item_right">{{item.excellentCount}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {articleExcellent} from "@/api/article";

  export default {
    data() {
      return {
        data: []
      }
    },
    created() {
      this.loadList();
    },
    methods: {
      async loadList() {
        this.data = await articleExcellent({
          size:50
        })
      },
      jumpDetail(id) {
        this.$router.push({name: 'topicDetail', query: {id}})
      },
      goBack(){
        this.$router.back();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top_list{
    height: 100%;
    overflow-y: auto;
    .top_header{
      position: relative;
      width: 375px;
      height: 200px;
      background-image: url("/images/topRankList/bg.png");
      background-repeat:no-repeat;
      background-size: 375px 210px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .back_button{
        height: 30px;
        font-size: 20px;
        padding-left: 12.5px;
        padding-right: 12.5px;
        width: 30px;
        color: #ffffff;
        position: absolute;
        top: 10px;
        left: 0;
      }
      .icon_img{
        width: 238px;
        height: 88px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    .top_content{
      margin-bottom: 10px;
      .top_info{
        //padding: 0 12.5px 20px;
        padding: 0 12.5px;
        font-size: 12px;
        height: 40px;
        line-height: 40px;
        color: $moreColor;
      }
      .top_list_warp{

        .top_item{
          padding: 0 12.5px;
          background: #ffffff;
          margin-bottom: 1px;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 44px;
          line-height: 44px;
          .item_left{
            width: 303px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
          .item_index{
            width: 20px;
            text-align: center;
            img{
              width: 100%;
              height: 100%;
              position: relative;
              top: 3px;
            }
            span{
              color: $mainColor;
              line-height: 20px;
              text-align: center;
              font-weight: 500;
            }
          }
          .item_title{
            width: 266px;
            max-width: 266px;
            flex-direction: row;
            justify-content: left;
            height: 100%;
            /*line-height: 100%;*/
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }

          .item_right{
            font-size: 12px;
            max-width: 43px;
            color: $moreColor;
          }
        }


      }
    }
  }
</style>
