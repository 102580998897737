import request from '@/utils/request'

export function articleAdd(data) {
    return request({
        url: '/api/zeekr-article-new/article/publish',
        method: 'post',
        data
    })
}

export function addBrowse(data) {
    return request({
        url: '/api/zeekr-article/article/addBrowse',
        method: 'get',
        data
    })
}

// 查看帖子详情
export function detail(params) {
    return request({
        url: '/api/zeekr-article-new/article/detail',
        method: 'get',
        params
    })
}

export function getCheckArticle(params) {
    return request({
        url: '/api/zeekr-article/article/getCheckArticle',
        method: 'get',
        params
    })
}

// 查询广场帖子列表
export function getByPage(params) {
    return request({
        url: '/api/zeekr-article-new/article/listSquare',
        method: 'get',
        params
    })
}

// 查询我加入圈子的帖子列表
export function getMeIntrant(params) {
    return request({
        url: '/api/zeekr-article-new/circleArticle/listJoinedCircleArticle',
        method: 'get',
        params
    })
}

// 查询圈子帖子列表
export function getCircleTopicList(params) {
    return request({
        url: '/api/zeekr-article-new/circleArticle/listCircleArticle',
        method: 'get',
        params
    })
}

export function getUserByPage(params) {
    return request({
        url: '/api/zeekr-article-new/article/listUser',
        method: 'get',
        params
    })
}
// /zeekr-article/article/search

export function getSearch(params) {
    return request({
        url: '/api/zeekr-article-new/articleSearch/keyword',
        method: 'get',
        params
    })
}

export function articleRank(data) {
    return request({
        url: '/api/zeekr-article/article/rank',
        method: 'get',
        data
    })
}
// 投票
export function addVote(data) {
    return request({
        url: '/api/zeekr-article-new/articleVoteRecord/save',
        method: 'post',
        data
    })
}

/**
 * 删除帖子
 * @param ids
 * @returns {AxiosPromise}
 */
// export function articleRemove(data) {
//     return request({
//         url: '/api/zeekr-article/article/remove',
//         method: 'post',
//         data
//     })
// }
export function articleRemove(params) {
    return request({
        url: '/api/zeekr-article-new/article/delete',
        method: 'get',
        params
    })
}

export function circleArticleRemove(params) {
    return request({
        url: '/api/zeekr-article-new/circleArticle/delete',
        method: 'get',
        params
    })
}

/**
 * 置顶圈内的帖子
 */
export function circleArticleTop(params) {
    return request({
        url: '/api/zeekr-article-new/circleArticle/top',
        method: 'get',
        params
    })
}

/**
 * 取消置顶圈内的帖子
 */
export function circleArticleCancelTop(params) {
    return request({
        url: '/api/zeekr-article-new/circleArticle/cancelTop',
        method: 'get',
        params
    })
}

/**
 * 获取用户文章统计
 * @param userId
 * @returns {AxiosPromise}
 */
export function articleCountByUserId(userId) {
    return request({
        url: '/api/zeekr-article/article/countByUserId',
        method: "get",
        params: { userId }
    })
}

/**
 * 获取热帖总数
 * @returns {AxiosPromise}
 */
//  export function articleHotCount() {
//     return request({
//         url: '/api/zeekr-article/article/get-today-hot-count',
//         method: "get"
//     })
// }

/**
 * 获取社区活动总数
 * @returns {AxiosPromise}
 */
//  export function activityCount() {
//     return request({
//         url: '/api/zeekr-article/article/in-progress-activity',
//         method: "get"
//     })
// }

/**
 * 根据标签查询帖子
 * @param tag
 * @param pageNum
 * @param PageSize
 * @returns {AxiosPromise}
 */
export function articleByTag(params) {
    return request({
        url: '/api/zeekr-article-new/articleSearch/topicTitle',
        method: "get",
        params
    })
}

/**
 * 跑马灯
 * @returns {AxiosPromise}
 */
//  export function newArticle() {
//     return request({
//         url: '/api/zeekr-article/article/get-new-article',
//         method: "get"
//     })
// }

/**
 * 添加评价
 * @returns {*}
 */
export function addEvaluation(data) {
    return request({
        url: '/api/zeekr-article/article-evaluation',
        method: "POST",
        data
    })
}

/**
 * todo 近7天热帖
 */
export function articleExcellent(params) {
    return request({
        url: '/api/zeekr-article-new/article/hotDiscussion',
        method: 'get',
        params
    })
}
